import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="ocr"
export default class extends Controller {
    static targets = [
        "uploadInfoPlaceholder",
        "uploadImagePlaceholder",
        "uploadImageName",
        "langField",
        "currentLangOption",
        "currentLangOptionValue",
        "langOption",
        "imageField",
    ]

    static classes = [
        "langOptionActive"
    ]

    connect() {

    }

    pasteFile(e) {
        this.imageFieldTarget.files = e.clipboardData.files;

        this.displayAttachedImage()
    }

    selectLang(e) {
        let langOption = e.target
        this.langFieldTarget.value = langOption.dataset.value
        this.currentLangOptionTarget.innerText = langOption.innerText
        this.setOptionActivated(langOption)
    }

    setOptionActivated(langOption){
        this.langOptionTargets
            .find(opt => [...opt.classList].includes(this.langOptionActiveClass))
            .classList
            .remove(this.langOptionActiveClass)
        langOption.classList.add(this.langOptionActiveClass)
    }

    displayAttachedImage() {
        if (!this.imageFieldTarget.files) return
        let file = this.imageFieldTarget.files[0]
        if (!file) return

        // this.uploadInfoPlaceholderTarget.innerHTML = "file uploaded"

        let fileReader = new FileReader()
        fileReader.onload = (e) => {
            this.uploadImagePlaceholderTarget.src = e.target.result
            this.uploadImagePlaceholderTarget.classList.remove("d-none")
        }
        fileReader.readAsDataURL(file)
        this.uploadImageNameTarget.innerText = file.name
    }
}
