import { Controller } from "@hotwired/stimulus"
import mediumZoom from "medium-zoom";

// Connects to data-controller="medium-zoom"
export default class extends Controller {
  connect() {
    let zoom = mediumZoom('.upload-image-placeholder', {
      background: '#31363F',
    })
  }
}
